import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

const AdminContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingLeft: '0px',
    paddingRight: '0px'
  },
  '&.MuiContainer-maxWidthLg': { // 使用 CSS 属性选择器
    maxWidth: 'none' // 覆盖 Container 达到最大宽度时的样式
  }
}));

export default AdminContainer;
